@import "@/assets/scss/main.scss";

.drop-zone-pdf {
    aspect-ratio: 1;
    width: 75%;
    border-radius: 50%;
    background-image: url("~@/assets/images//elsisat/Zielscheibe.svg");
    background-size: cover;
    height: 75%;
    padding: 0;
    padding-bottom: calc(100% * 3 / 4);
    background-repeat: no-repeat;
    background-position: center center;
}

.item {
    position: absolute;
}

.drag-el {
    background: #fff;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
    padding: 8px 20px 8px 20px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    text-align: center;
    cursor: move;
    /* fallback: no `url()` support or images disabled */
    cursor: -webkit-grab;
    /* Chrome 1-21, Safari 4+ */
    cursor: -moz-grab;
    /* Firefox 1.5-26 */
    cursor: grab;
    /* W3C standards syntax, should come least */

    &:hover {
        background: darken($elsi-color-blue, 8);
        color: white;
        text-align: center;
    }
}

.notes-pdf {
    padding-top: 32px;
    text-align: justify;
}
